<template>
  <div :class="rootClasses" class="splash-screen">
    <div class="splash-screen__layout layout layout--full-width">
      <div class="layout__container">
        <div class="splash-screen__spacer">
          <n-link :to="localePath({ name: 'viewpoint' })" class="u-reset splash-screen__map-outer">
            <div class="splash-screen__map-inner">
              <world-map
                class="splash-screen__map"
                v-bind="mapConfig"
                :facets="['splash-screen']"
                :map-padding="[4, 4, 4, 4]"
                :projection-morph-duration="2000"
              />
              <span class="splash-screen__cta">
                <span class="splash-screen__cta-btn btn btn--has-grid">
                  <span class="btn__group-items">
                    <span class="btn__group-item">
                      {{ $t('page_intro.button_label') }}
                    </span>
                    <span class="btn__group-item">
                      <span class="btn__group-item-icon">
                        <responsive-img
                          :width="41"
                          alt=""
                          src="/images/arrow_icons/custom/arrow_right_neg@3x.png"
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </n-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import WorldMap from '@/components/world-map/world-map'
  import { mapConfigIds, mapConfigList } from '@/assets/js/model/map-config'
  import ResponsiveImg from '@/components/responsive-img/responsive-img'
  import {
    startIntroAnimation,
    startIntroProjectionMorphTween,
    startIntroProjectionRotateTween,
  } from '@/components/splash-screen/util'
  import bemMixin from '~/assets/js/mixin/bem'

  export default {
    components: { ResponsiveImg, WorldMap },
    mixins: [bemMixin('splash-screen')],
    props: {},
    data() {
      return {
        hasStarted: false,
        introAnimation: {
          tween: null,
          rotationLambda: 0,
          rotationPhi: 0,
          rotationGamma: 0,
          zoom: 0,
        },
        introProjectionMorph: {
          tween: null,
        },
        introProjectionRotate: {
          tween: null,
          rotationLambda: 0,
          rotationPhi: 0,
          rotationGamma: 0,
        },
      }
    },
    computed: {
      ...mapState('cookie-banner', ['isAccepted']),
      ...mapState('viewport', { viewportWidth: 'width', viewportHeight: 'height' }),
      ...mapState('world-map', ['mapConfigs']),
      mapConfig() {
        return this.mapConfigs[mapConfigIds.SPLASH_SCREEN]
      },
      rootClasses() {
        return [this.bemAdd(this.hasStarted ? 'has-started' : null)]
      },
    },
    watch: {
      isAccepted: {
        handler(value) {
          if (value) {
            startIntroAnimation.call(this, 1, 2)
            startIntroProjectionMorphTween.call(this, false, 3, 2)
            startIntroProjectionRotateTween.call(this, 3, 10)
          }
        },
        immediate: true,
      },
    },
    beforeDestroy() {
      this.introAnimation.tween && this.introAnimation.tween.kill()
      this.introProjectionMorph.tween && this.introProjectionMorph.tween.kill()
      this.introProjectionRotate.tween && this.introProjectionRotate.tween.kill()
      this.setMapConfig(mapConfigList.find(({ id }) => id === mapConfigIds.SPLASH_SCREEN))
    },
    methods: {
      ...mapActions('world-map', ['setMapConfig', 'updateMapConfig']),
    },
  }
</script>

<style lang="scss" src="./_splash-screen.scss" />
