import { Expo, gsap, Linear } from 'gsap'
import { randomRotation } from '@/assets/js/util/misc/number'
import { projectionIds, projectionList } from '~/assets/js/model/projection'

const morphableProjectionList = projectionList
  .filter(({ isMorphable }) => isMorphable)
  .map(({ id }) => id)
const getRandomMorphableProjection = gsap.utils.random(morphableProjectionList, true)

export const introAnimationConfig = {
  fromVars: {
    rotationLambda: 0,
    rotationPhi: 0,
    rotationGamma: 0,
    zoom: 0,
  },
  toVars: {
    rotationLambda: randomRotation,
    rotationPhi: randomRotation,
    rotationGamma: randomRotation,
    zoom: 1,
    paused: true,
    immediateRender: false,
    ease: Expo.easeOut,
    onUpdate() {
      const vars = this.introAnimation.tween.targets()[0]

      this.updateMapConfig({
        id: this.mapConfig.id,
        rotation: [vars.rotationLambda, vars.rotationPhi, vars.rotationGamma],
        zoom: vars.zoom,
      })
    },
  },
}

export function startIntroAnimation(delay, duration) {
  this.introAnimation.tween = gsap.fromTo(
    this.introAnimation,
    {
      rotationLambda: 0,
      rotationPhi: 0,
      rotationGamma: 0,
      zoom: 0,
    },
    {
      rotationLambda: randomRotation,
      rotationPhi: randomRotation,
      rotationGamma: randomRotation,
      zoom: 1,
      paused: true,
      immediateRender: false,
      ease: Expo.easeOut,
      callbackScope: this,
      onStart() {
        this.hasStarted = true
      },
      onUpdate() {
        const vars = this.introAnimation.tween.targets()[0]

        this.updateMapConfig({
          id: this.mapConfig.id,
          rotation: [vars.rotationLambda, vars.rotationPhi, vars.rotationGamma],
          zoom: vars.zoom,
        })
      },
    },
  )

  this.introAnimation.tween.delay(delay).duration(duration).restart(true)
}

export function startIntroProjectionMorphTween(reverse, delay, duration) {
  this.introProjectionMorph.tween = gsap.fromTo(
    this.introProjectionMorph,
    {
      t: 0,
    },
    {
      t: 1,
      paused: true,
      immediateRender: false,
      callbackScope: this,
      onStart(projectionId) {
        this.updateMapConfig({
          id: this.mapConfig.id,
          projectionId,
        })
      },
      onComplete() {
        const reverse = !this.introProjectionMorph.tween.data.reverse
        const animationParams = reverse ? [4, 2] : [0, 2]
        startIntroProjectionMorphTween.call(this, reverse, ...animationParams)
      },
      data: {
        reverse,
      },
      onStartParams: [reverse ? projectionIds.ORTHOGRAPHIC : getRandomMorphableProjection()],
    },
  )

  this.introProjectionMorph.tween.delay(delay).duration(duration).restart(true)
}

export function startIntroProjectionRotateTween(delay, duration) {
  this.introProjectionRotate.tween = gsap.fromTo(
    this.introProjectionRotate,
    {
      rotationLambda: 0,
      rotationPhi: 0,
      rotationGamma: 0,
    },
    {
      rotationLambda: 360,
      rotationPhi: 0,
      rotationGamma: 0,
      paused: true,
      repeat: -1,
      immediateRender: false,
      callbackScope: this,
      ease: Linear,
      onUpdate() {
        const speed = 0.05

        this.updateMapConfig({
          id: this.mapConfig.id,
          rotation: [
            ((this.mapConfig.rotation[0] + 180 + speed) % 360) - 180,
            this.mapConfig.rotation[1],
            this.mapConfig.rotation[2],
          ],
        })
      },
    },
  )

  this.introProjectionRotate.tween.delay(delay).duration(duration).restart(true)
}
