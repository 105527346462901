
  import SplashScreen from '~/components/splash-screen/splash-screen.vue'
  import ResponsiveImg from '~/components/responsive-img/responsive-img.vue'

  export default {
    meta: {
      titleKey: 'meta.home.title',
    },
    components: { ResponsiveImg, SplashScreen },
    head() {
      return {
        script: [{ src: 'https://identity.netlify.com/v1/netlify-identity-widget.js' }],
      }
    },
  }
